const domainLocaleMap = {
    //English language domains
    '99designs.com': 'en_US',
    '99designs.com.au': 'en_AU',
    '99designs.ca': 'en_CA',
    '99designs.co.uk': 'en_GB',
    '99designs.ie': 'en_IE',
    'en.99designs.de': 'en_DE',
    '99designs.dk': 'en_DK',
    'en.99designs.fr': 'en_FR',
    'en.99designs.it': 'en_IT',
    '99designs.no': 'en_NO',
    'en.99designs.nl': 'en_NL',
    'en.99designs.com.br': 'en_BR',
    'en.99designs.pt': 'en_PT',
    'en.99designs.cl': 'en_CL',
    'en.99designs.com.co': 'en_CO',
    'en.99designs.com.mx': 'en_MX',
    'en.99designs.es': 'en_ES',
    'en.99designs.jp': 'en_JP',
    '99designs.hk': 'en_HK',
    '99designs.com.sg': 'en_SG',
    'en.99designs.ch': 'en_CH',
    'en.99designs.be': 'en_BE',
    'en.99designs.at': 'en_AT',

    //French language domains
    'fr.99designs.ch': 'fr_CH',
    'fr.99designs.be': 'fr_BE',
    '99designs.fr': 'fr_FR',

    //German language domains
    '99designs.at': 'de_AT',
    '99designs.de': 'de_DE',
    '99designs.ch': 'de_CH',

    //Japanese language domain
    '99designs.jp': 'ja_JP',

    //Other language domains
    'es.99designs.com': 'en_US',
    '99designs.it': 'en_IT',
    '99designs.nl': 'en_NL',
    '99designs.com.br': 'en_BR',
    '99designs.pt': 'en_PT',
    '99designs.cl': 'en_CL',
    '99designs.com.co': 'en_CO',
    '99designs.com.mx': 'en_MX',
    '99designs.es': 'en_ES',
    'it.99designs.ch': 'en_CH',
};

export default domainLocaleMap;
