import trackLink from '@99designs/common/utils/platform/trackLink';
import createScrollableElements, { scrollOnHover } from 'utils/scrollable';
import domainLocaleMap from './domainLocaleMap';

require('lib/jquery.dotdotdot');

(function ($) {
    let tld = window.location.hostname.replace('.dockervm', '');
    if (typeof geoLocationDomain99d !== 'undefined') {
        // eslint-disable-next-line no-undef
        tld = geoLocationDomain99d.replace('.dockervm', '');
    }
    const locale = domainLocaleMap[tld] ? domainLocaleMap[tld] : 'en_US';

    $.get({
        url: '/blog/wp-admin/admin-ajax.php',
        data: {
            action: 'get_contests_json',
            locale: locale,
        },
        success: handleSuccess,
    });
})(jQuery);

function handleSuccess(data) {
    data = data.data || [];

    $('[data-contests-section-body]').html(data['contests_html']);

    $('[data-contests-list-item-title]').dotdotdot();

    initScrollContainer();

    setupLinkTracking();
}

function setupLinkTracking() {
    const contestsSection = document.querySelector('[data-contests-section]');

    Array.from(contestsSection.querySelectorAll('[data-contests-section-cta]')).forEach(
        (element) => {
            const location = element.getAttribute('data-contests-section-cta-location');
            trackLink(element, 'Clicked Contests Section CTA On Blog', { cta_location: location });
        }
    );
}

function initScrollContainer() {
    const scrollableSelector = '[data-scrollable="contests_scroller"]';
    const scrollable = document.querySelector(scrollableSelector);
    const scrollableInner = scrollable.querySelector('[data-scrollable-inner]');
    const maxScrollLeft = scrollableInner.scrollWidth - scrollableInner.clientWidth;

    scrollableInner.scrollLeft = maxScrollLeft / 2;

    createScrollableElements(scrollableSelector);
    scrollOnHover(scrollableSelector);
}
