import MediaQueryLabel from '@99designs/common/jquery-plugins/media-query-label/lib/media-query-label';

export default class VelocityScrollable {
    constructor(element, container, raf) {
        this.element = element;
        this.container = container;
        this.raf = raf;
        this.velocity = 0;
        this.threshold = 300;
        this.maxVelocity = 10;

        this.waitForMovement();
        this.startVelocityDetection();
    }

    waitForMovement() {
        this.raf.call(null, () => {
            this.move(this.velocity);
            this.waitForMovement();
        });
    }

    startVelocityDetection() {
        this.element.addEventListener(
            'mousemove',
            (e) => {
                let left = e.clientX;
                let right = this.container.clientWidth - e.clientX;
                if (new MediaQueryLabel().init().is('small')) {
                    this.velocity = 0;
                } else {
                    this.velocity = this.calculateVelocity(left, right);
                }
            },
            false
        );

        this.element.addEventListener(
            'mouseout',
            () => {
                this.velocity = 0;
            },
            false
        );
    }

    calculateVelocity(left, right) {
        if (left < this.threshold) {
            return ((this.threshold - left) / this.threshold) * -this.maxVelocity;
        } else if (right < this.threshold) {
            return ((this.threshold - right) / this.threshold) * this.maxVelocity;
        } else {
            return 0;
        }
    }

    move(offset) {
        this.element.scrollLeft += offset;
    }
}
