import VelocityScrollable from './VelocityScrollable';

/**
 * Adjust the spacing of a horizontally scrollable element to remove extra spacing
 * caused by browsers.
 */
export default function createScrollableElements(selector = '[data-scrollable]') {
    const scrollables = document.querySelectorAll(selector);

    [].forEach.call(scrollables, (scrollable) => {
        const defaultHeightDifference = -20;

        let inner = scrollable.querySelector('[data-scrollable-inner]');
        let heightDifference = inner.clientHeight - scrollable.clientHeight;

        if (heightDifference === 0) {
            inner.style.marginTop = defaultHeightDifference + 'px';
            inner.style.bottom = defaultHeightDifference + 'px';
            inner.style.paddingBottom = -1 * defaultHeightDifference + 'px';
        } else {
            inner.style.marginTop = heightDifference + 'px';
            inner.style.bottom = heightDifference + 'px';
        }
    });
}

export function centerAlignScrollableElement(selector = '[data-scrollable]') {
    const $element = $(`${selector} [data-scrollable-inner]`);

    return new Promise((resolve) => {
        $element.each((idx, el) => {
            el.scrollLeft = ($(el).data('size') - window.innerWidth) / 2;
        });

        resolve($element);
    });
}

export function scrollOnHover(selector = '[data-scrollable]') {
    const scrollables = document.querySelectorAll(selector);

    [].forEach.call(scrollables, (scrollable) => {
        let inner = scrollable.querySelector('[data-scrollable-inner]');

        new VelocityScrollable(
            inner,
            document.documentElement,
            window.requestAnimationFrame.bind(window)
        );
    });
}
